// src/services/auth.js
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export const login = async (username, password) => {

    return axios.post(API_URL + 'api/token/', {
        email: username,
        password: password,
    });

};

export const getProtectedData = async (token) => {

    return axios.get(API_URL + 'api/protected/', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

};

export const register = async (firstName, lastName, username, password1, password2, phone) => {
    return axios.post(API_URL + 'api/register/', {
        first_name: firstName,
        last_name: lastName,
        email: username,
        password1: password1,
        password2: password2,
        phone_number: phone
    });
};

export const refreshToken = async () => {
    try {
        const response = await axios.post(`/api/token/refresh/`, {
            refresh: sessionStorage.getItem('refresh_token')
        });
        const { token } = response.data;
        sessionStorage.setItem('token', token);
        return token;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        return null;
    }
};
