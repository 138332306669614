<template>
  <div class="bg-white rounded-lg shadow md:mt-4 mt-2 overflow-x-auto">
    <table class="min-w-full leading-normal">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column"
            class="md:text-md sm:text-sm text-xs md:px-5 px-2 md:py-3 py-1 border-b-2 border-gray-200 bg-gray-100 text-start font-semibold text-gray-600 uppercase tracking-wider"
          >
            {{ formatHeader(column) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in tableData" :key="row.rid">
          <tr @click="toggleDetails(row.rid)">
            <td
              v-for="column in columns"
              :key="column"
              class="md:text-md sm:text-sm text-xs md:px-5 px-2 md:py-5 py-2 border-b border-gray-200 bg-white text-start"
            >
              <template v-if="column === 'state'">
                <span
                  :class="['inline-block w-3 h-3 rounded-full', getStatusClass(row.state)]"
                ></span>
              </template>
              <template v-else>
                {{ row[column] }}
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- Pagination Element -->
    <pagination-element
      :total-items="totalItems"
      :page-size="pageSize"
      :current-page="currentPage"
      @page-change="handlePageChange"
    />

    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="bg-black bg-opacity-50 absolute inset-0" @click="toggleDetails"></div>
      <div class="bg-white rounded-lg shadow-xl p-4 z-10 w-11/12 md:w-2/3 lg:w-1/2">
        <div class="flex justify-between items-center pb-4 border-b">
          <h2>Details for: <span class="text-lg font-semibold">{{ currentLocation }}</span></h2>
          <button @click="toggleDetails" class="text-gray-500 hover:text-gray-700 text-3xl mx-4">
            &times;
          </button>
        </div>
        <!-- Toggle Button to Switch Views -->
        <div class="pt-4 flex justify-end">
          <button @click="toggleView" class="bg-blue-500 text-white px-4 py-2 rounded">
            {{ buttonName }}
          </button>
        </div>

        <!-- Display the Current ViewTable -->
        <div class="pt-4">
          <ViewTable v-if="isDefaultView" :fetchString="getDetailsFetchString(currentRowId)" />
          <ViewTable v-else :fetchString="getSuggestedFetchString(currentRowId)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import PaginationElement from './PaginationElement.vue';
import ViewTable from './ViewTable.vue';

const props = defineProps({
  fetchString: {
    type: String,
    required: true
  },
  detailsFetchString: {
    type: String,
    required: true
  },
  pageSize: {
    type: Number,
    default: 10
  }
});

const columns = ref([]);
const tableData = ref([]);
const totalItems = ref(0);
const currentPage = ref(1);
const currentRowId = ref(null);
const currentLocation = ref(null);
const showModal = ref(false);
const isDefaultView = ref(true); // Track which view is active
const buttonName = ref('suggested parts');
const API_URL = process.env.VUE_APP_API_URL;


const formatHeader = (header) => {
  return header
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

async function fetchData() {
  try {
    const url = `${API_URL}${props.fetchString}&page=${currentPage.value}&page_size=${props.pageSize}`;
    const response = await axios.get(url);
    tableData.value = response.data.results;
    columns.value = tableData.value.length > 0 ? Object.keys(tableData.value[0]) : [];
    totalItems.value = response.data.total_items;
  } catch (error) {
    console.error('Error fetching data:', error);
    tableData.value = [];
    totalItems.value = 0;
  }
}

const toggleDetails = (rowId = null) => {
  if (rowId !== null) {
    const selectedRow = tableData.value.find((row) => row.rid === rowId);
    if (selectedRow) {
      currentRowId.value = rowId;
      currentLocation.value = selectedRow.route || 'Unknown Location';
    } else {
      console.error(`Row with id ${rowId} not found.`);
    }
  }
  showModal.value = !showModal.value;
};

const getDetailsFetchString = (rowId) => {
  return props.detailsFetchString.replace('{id}', rowId);
};

const handlePageChange = (page) => {
  currentPage.value = page;
  fetchData();
};

const getStatusClass = (state) => {
  switch (state) {
    case 'red':
      return 'bg-red-600';
    case 'amber':
      return 'bg-orange-200';
    case 'purple':
      return 'bg-purple-400';
    default:
      return 'bg-gray-300'; // Fallback color
  }
};

const toggleView = () => {
  isDefaultView.value = !isDefaultView.value;
  buttonName.value = isDefaultView.value ? 'suggested parts' : 'log details';
  currentPage.value = 1; // Reset the current page to 1 whenever the view is toggled
};

const getSuggestedFetchString = (rowId) => {
  return `logs/get_suggested_components/?route=${currentLocation.value}&log_id=${rowId}`;
};

// Fetch data when component is mounted
onMounted(fetchData);

// Reset currentPage and fetch data when fetchString changes
watch(() => props.fetchString, () => {
  currentPage.value = 1; // Reset the current page to 1
  fetchData();
});

</script>

<style scoped>
.fixed.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
