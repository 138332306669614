<template>
  <div class="bg-white rounded-lg shadow md:mt-4 mt-2 overflow-x-auto">
    <!-- Heading -->
    <h3 v-if="loading" class="text-center text-gray-500">Loading...</h3>
    <h3 v-if="error" class="text-center text-red-500">{{ error }}</h3>

    <!-- Table Data -->
    <table v-if="!loading && !error && data.length" class="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th
            class="md:text-md sm:text-sm text-xs md:px-20 px-2 md:py-3 py-1 border-b-2 border-gray-200 bg-gray-100 text-start font-semibold text-gray-600 uppercase tracking-wider"
            v-for="(header, index) in tableHeaders"
            :key="index"
          >
            {{ columnDisplayNames[header] || header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in data" :key="rowIndex" class="bg-white">
          <td
            class="md:text-md sm:text-sm text-xs md:px-20 px-2 md:py-5 py-2 border-b border-gray-200 bg-white text-start"
            v-for="(value, colIndex) in row"
            :key="colIndex"
          >
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- No Data State -->
    <p v-if="!loading && !error && !data.length" class="text-center text-gray-500">No data available</p>

    <!-- Pagination Element -->
    <pagination-element
      v-if="!loading && !error && totalItems > pageSize"
      :total-items="totalItems"
      :page-size="pageSize"
      :current-page="currentPage"
      @page-change="handlePageChange"
    />
  </div>
</template>

<script>
import axios from 'axios';
import PaginationElement from './PaginationElement.vue';

const API_URL = process.env.VUE_APP_API_URL; // Base API URL from environment variables

export default {
  props: {
    fetchString: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  components: {
    PaginationElement, // Register the PaginationElement component
  },
  data() {
    return {
      data: [], // The data for the table
      loading: false, // Loading state
      error: null, // Error state
      tableHeaders: [], // Table headers
      currentPage: 1, // The current page for pagination
      pageSize: 10, // Page size (could be dynamic)
      totalItems: 0, // Total items for pagination
      columnDisplayNames: {
        time_occurred: 'Time Occurred', // Add more mappings as needed
      },
    };
  },
  watch: {
    fetchString: {
      immediate: true, // Fetch data as soon as the component is mounted

      handler() {
        this.currentPage = 1;
        this.fetchData(); // Fetch data when fetchString changes
      },
    },
    startDate: {
      immediate: true,
    handler() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
  endDate: {
    immediate: true,
    handler() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
  currentPage: {
    handler() {
      this.fetchData();
    },
  },
},

  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;

      try {
        const response = await axios.get(`${API_URL}${this.fetchString}`, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            startDate: this.startDate,
            endDate: this.endDate
          },
        });

        // Assuming the API returns data in 'results' and 'total_items' format
        this.data = response.data.results || [];
        this.totalItems = response.data.total_items || 0;

        // Extract table headers from the first item in the results
        if (this.data.length > 0) {
          this.tableHeaders = Object.keys(this.data[0]);
        }
      } catch (err) {
        this.error = 'Failed to fetch data';
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
  },
};
</script>

<style scoped>
/* Add custom styling if necessary */
</style>
