<template>
  <div class="flex flex-col items-center justify-center my-6">
    <h1>Account Activation</h1>
    <p v-if="message">{{ message }}</p>
    <div v-if="showResendLink">
      <form @submit.prevent="resendActivationLink" class="max-w-md">
        <input v-model="email"
               type="email"
               placeholder="Enter your email"
               required
               class="w-full my-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
        <button type="submit"
                class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Resend Activation Link
        </button>
      </form>
    </div>
    <div v-if="activationSuccessful">
      <button @click="goToLogin"
              class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Go to Login
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const message = ref('');
const showResendLink = ref(false);
const email = ref('');
const activationSuccessful = ref(false);

const API_URL = process.env.VUE_APP_API_URL;

onMounted(async () => {
  const token = route.params.token;
  await activateAccount(token);
});

const activateAccount = async (token) => {
  try {
    const response = await axios.get(`${API_URL}accounts/activate/${token}/`);
    message.value = response.data.message;
    activationSuccessful.value = true;

    // Redirect to password reset
     router.push(`/reset-password?token=${token}`);
  } catch (error) {
    message.value = error.response.data.message;
    if (error.response.data.error_code === 'expired') {
      showResendLink.value = true;
    }
  }
};

const resendActivationLink = async () => {
  try {
    const response = await axios.post(`${API_URL}accounts/resend-activation-link/`, { email: email.value });
    message.value = response.data.message;
    showResendLink.value = false;
  } catch (error) {
    message.value = error.response.data.message;
  }
};

const goToLogin = () => {
  router.push({ name: 'LoginPage' });
};
</script>

<style scoped>
/* Add your styles here */
</style>
