<template>
  <div>
    <!-- The main app content -->
    <div id="app" v-show="isLandscape" class="sm:flex-row flex-col flex">
      <SideBar v-if="showSidebar" />
      <router-view />
      <BurgerMenu v-if="showSidebar"/>
    </div>
    

    <!-- Rotate notice for portrait mode -->
    <div v-show="!isLandscape" class="rotate-notice">
      <img :src="RotateImage" alt="Rotate Device" />
      <p>Please rotate your device to landscape orientation.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import SideBar from './components/SideBar.vue';
import RotateImage from './assets/images/rotating.gif';
import BurgerMenu from './components/BurgerMenu.vue';


// Route setup
const route = useRoute();
const hideOnPages = ['/login', '/forgot-password', '/logout'];
const showSidebar = computed(() => !hideOnPages.includes(route.path));

// Detect device orientation
const isLandscape = ref(window.matchMedia('(orientation: landscape)').matches);

const updateOrientation = () => {
  isLandscape.value = window.matchMedia('(orientation: landscape)').matches;
};

onMounted(() => {
  window.addEventListener('resize', updateOrientation);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateOrientation);
});
</script>

<style lang="scss">
/* Styling for app content */

.material-symbols-rounded {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1E145E;
}

:root {
  --primary: #d95661;
  --grey: #052249;
  --dark: #201162;
  --dark-alt: #b3b4c2e3;
  --light: #ffffff;
  --sidebar-width: 400px;
}

body {
  background: var(--light);
}

button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}

/* Rotate notice styling */
.rotate-notice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--dark);
  color: var(--light);
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem;
}

.rotate-notice img {
  max-width: 150px;
  margin-bottom: 1rem;
}
</style>
